<!--
 * @Description: 评级白名单
 * @Date: 2019-12-24 15:17:08
 * @LastEditTime: 2020-06-22 18:52:47
 * @LastEditors: 前胡
 -->
<template>
  <div id="list">
    <WhiteListSearchBar :searchParams="searchParams" :loading="userListLoading" @callback="handleSearch" />
    <div class="btn-box">
      <a-button type="primary" @click="addUser" v-auth="'user_manage_modify_white_list'">添加白名单</a-button>
    </div>
    <a-table
      :scroll="{ y: tableScrollY }"
      v-auth="'user_manage_white_list_page'"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination"
      :dataSource="list"
      :locale="{ emptyText: '暂无白名单' }"
      :loading="userListLoading"
      @change="handleTableChange"
    >
      <!--<template slot="channel_tag" slot-scope="record">
        <a-tag v-if="record.channel===2" color="orange">淘宝</a-tag>
        <a-tag v-if="record.channel===1" color="purple">抖音</a-tag>
      </template>-->
      <template slot="fans" slot-scope="record">
        <span v-if="record.taobao_fans_count === -1">暂无数据</span>
        <span v-else>{{record.taobao_fans_count}}</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button
          size="small"
          type="primary"
          @click="handleDelUser(record)"
          v-auth="'user_manage_modify_white_list'"
        >删除</a-button>
      </template>
      <template slot="money" slot-scope="money">
        <span :style="`${money == 0 ? 'color:#ccc;' : ''}`">￥{{money}}</span>
      </template>
    </a-table>
    <!--添加白名单modal-->
    <div v-if="visibleAddUserModal">
      <a-modal
        title="添加白名单"
        :visible="visibleAddUserModal"
        :confirm-loading="posttingMobile"
        okText="确认添加"
        @ok="handleAddUserOk"
        @cancel="handleAddUserCancel"
      >
        <a-form
          class="filter-condition-form"
          :form="mobileForm"
          @submit="handleFormSubmit"
          v-auth="'user_manage_user_list'"
        >
          <a-form-item label="注册手机" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-input
              style="width:300px"
              :maxLength="13"
              v-decorator="[
          'mobile',
          { rules: [{ required: true, message: '请输入注册手机' },{validator: checkPhoneNumber}]  },
        ]"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>

const columns = [
/*  {
    title: "渠道",
    align: "center",
    fixed: 'left',
    scopedSlots: { customRender: "channel_tag" },
    width: 80
  },*/
  {
    title: "注册手机",
    fixed: 'left',
    dataIndex: "mobile",
    align: "center",
    width: 120
  },
  {
    title: "用户昵称",
    dataIndex: "user__nickname",
    width: 220,
    align: "center"
  },
  {
    title: "操作人",
    dataIndex: "add_by__username",
    width: 120,
    align: "center"
  },
  {
    title: "加入时间",
    dataIndex: "create_time",
    width: 240,
    align: "center"
  },
  {
    title: "操作",
    key: "operation",
    fixed: 'right',
    align: "center",
    width: 120,
    scopedSlots: { customRender: "operation" }
  }
];

import {
  getWhiteListData,
  postWhiteList,
  delWhiteList
} from "../../../service/user";
import { checkPer } from "@/utils/index";
import { checkPhoneNumber } from "@/utils/validator";
import WhiteListSearchBar from '@/components/admin/user/white-list-search-bar.vue'

export default {
  name: "RateWhiteList",
  components: {
    WhiteListSearchBar
  },
  data() {
    return {
      tableScrollY: 600,
      checkPhoneNumber,
      visibleAddUserModal: false,
      posttingMobile: false,
      form: this.$form.createForm(this),
      mobileForm: this.$form.createForm(this),
      columns,
      page: 1,
      list: [],
      userListLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      userDetailDrawerVisiable: false,
      drawerProps: { id: null },
      searchParams: {
        search:'',
        // channel: undefined
      }
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 340;
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    // 添加白名单
    addUser() {
      this.visibleAddUserModal = true;
    },
    // 隐藏白名单modal
    handleAddUserCancel() {
      this.visibleAddUserModal = false;
    },
    // 提交白名单数据
    handleAddUserOk() {
      this.handleAddUserFormSubmit();
    },
    // 新增白名单
    handleAddUserFormSubmit() {
      this.mobileForm.validateFields(async (err, values) => {
        if (!err) {
          const data = { mobile: values.mobile };
          this.posttingMobile = true;
          const { err, res } = await postWhiteList(data);
          this.posttingMobile = false;
          if (!err) {
            if (res.success) {
              this.visibleAddUserModal = false;
              this.$message.success(res.message);
              this.pagination.current = 1;
              this.getUserList();
            }
          }
        }
      });
    },
    // 白名单搜索
    handleSearch(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getUserList();
    },
    // 获取白名单列表
    async getUserList() {
      if (!checkPer("user_manage_white_list_page")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      this.userListLoading = true;
      const params = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ordering: "-create_time",
        search: this.searchParams.search,
        // channel: this.searchParams.channel
      };
      const { err, res } = await getWhiteListData({params});

      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      }
      this.userListLoading = false;
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getUserList();
    },
    // 搜索框数据发生变化
    handleSearchInputChange(e) {
      if (e.target.value === "") this.getUserList();
    },
    // 移出白名单
    handleDelUser(record) {
      const that = this;
      this.$confirm({
        title: "移出白名单",
        content: () => (
          <div>
            确认要将【 <span style="color:red;">{record.user__nickname}</span>{" "}
            】移出白名单吗?
          </div>
        ),
        okText: "确认移出",
        async onOk() {
          const { err, res } = await delWhiteList(record.id);
          if (!err) {
            if (res.success) {
              that.$message.success(res.message)
              that.pagination.current = 1;
              that.getUserList();
            }
          }
        },
        onCancel() {
        }
      });
    }
  }
};
</script>

<style lang='less' scoped>
.btn-box {
  padding: 10px 0;
}
</style>
